import filetype from "magic-bytes.js"
import mime from "mime"

export enum ErrorFileCode {
  LOAD = 1,
  MAX_SIZE = 2,
  FILE_TYPE = 3,
}
class ErrorFileLoad extends Error {
  readonly code: ErrorFileCode.LOAD = ErrorFileCode.LOAD
}
class ErrorFileSize extends Error {
  readonly code: ErrorFileCode.MAX_SIZE = ErrorFileCode.MAX_SIZE
}
class ErrorFileType extends Error {
  readonly code: ErrorFileCode.FILE_TYPE = ErrorFileCode.FILE_TYPE
}

export type ErrorFile = ErrorFileLoad | ErrorFileSize | ErrorFileType

export type ValueFileUploaderFile = {
  value: string
  name: string
  size: number
  type: string
}
export type ValueFileUploader = string | ValueFileUploaderFile

export const isFileLike = (file?: any): file is ValueFileUploaderFile => {
  return Boolean(file && typeof file === "object" && file.value && file.name)
}
export const getFileAccept = (type?: string | string[]) => {
  const types = Array.isArray(type) ? type : String(type || "").split(",")

  const contentTypes = types.map(t => mime.getType(t))

  return {
    inputType: types.length ? types.join(", ") : "application/octet-stream",
    contentTypes: contentTypes.filter(Boolean) as string[],
  }
}
export const getFileInfo = (v?: ValueFileUploader | null) => {
  const isFile = isFileLike(v)
  const value = isFileLike(v) ? v.value : v || ""
  const fileName = isFileLike(v) ? v.name : getFileNameFromUrl(v || "")
  const fileUrl = value || ""

  return {
    isFile,
    fileUrl,
    fileName,
    value,
  }
}

export const fileToBase64 = (file: Blob) => {
  return new Promise<string>((res, rej) => {
    const fileReader = new FileReader()
    fileReader.onload = e => {
      if (!e.target) {
        return rej(new ErrorFileLoad("error-file-type"))
      }
      return res(String(e.target.result))
    }
    fileReader.onerror = rej
    fileReader.readAsDataURL(file)
  })
}
export const base64ToFileStream = (base64: string) => {
  return base64.split(",")[1]
}
export const cropImg = (file: Blob, width: number, height: number) => {
  return new Promise<string>((resolve, reject) => {
    const img_ = new Image()
    img_.src = URL.createObjectURL(file)
    img_.onload = () => {
      const imageWidth = img_.width
      const imageHeight = img_.height

      const ratio = Math.min(
        imageWidth > width ? width / imageWidth : 1,
        imageHeight > height ? height / imageHeight : 1,
      )

      if (ratio === 1) {
        fileToBase64(file).then(resolve, reject)
      } else {
        const canvas = document.createElement("canvas")
        const width_ = imageWidth * ratio
        const height_ = imageHeight * ratio
        canvas.width = width_
        canvas.height = height_
        const ctx = canvas.getContext("2d")
        ctx?.drawImage(
          img_,
          0,
          0,
          imageWidth,
          imageHeight,
          0,
          0,
          width_,
          height_,
        )
        resolve(canvas.toDataURL())
      }
    }
    img_.onerror = reject
  })
}

export const getFileNameWithoutExt = (fileName: string) => {
  return fileName.split(".")[0]
}
export const getFileNameFromUrl = (fileName: string) => {
  //eslint-disable-next-line
  return fileName.replace(/^.*[\\\/]/, "")
}

export const validateFileSize = async (file: File, maxSize: number) => {
  const isValid = file.size < maxSize * 1000000
  if (!isValid) {
    throw new ErrorFileSize("error-file-max-size")
  }
  return isValid
}
interface ValidateFileTypeOptions {
  file: Blob
  contentTypes: string[]
  skipContentTypes: string[]
}
export const validateFileType = async (options: ValidateFileTypeOptions) => {
  const { file, contentTypes, skipContentTypes } = options
  const { type } = file
  const validType = contentTypes.length && contentTypes.some(t => t === type)

  if (!validType) {
    throw new ErrorFileType("error-file-type")
  }

  if (skipContentTypes.some(skipType => skipType === type)) {
    return true
  }

  const result = filetype(new Uint8Array(await file.arrayBuffer()))

  if (!result) {
    throw new ErrorFileType("error-file-type")
  }

  const validTypeBlob =
    contentTypes.length &&
    contentTypes.some(type =>
      result.some(guessedFile => guessedFile.mime === type),
    )

  if (!validTypeBlob) {
    throw new ErrorFileType("error-file-type")
  }

  return true
}

export const getFileExt = (fileName: string) => {
  return fileName.split(".").at(-1)
}

export const makeUniqueFileName = <O extends { name: string }>(
  fieldName: string,
) => {
  return (option: O) => ({
    isImage: true,
    fileName: [
      [new Date().getTime(), fieldName].join("__"),
      getFileExt(option.name),
    ].join("."),
  })
}
